<template>
  <main class="container px-md-0 py-4">
    <h1>{{ $t('page-info.title') }}</h1>
    <section>
      <h3 v-html="$t('page-info.title-pool', { nbinstances: items.length })"></h3>
      <p v-html="$t('page-info.intro')"></p>
      <b-table
        striped
        hover
        :items="items"
        :fields="fields"
        :tbody-tr-class="rowClass"
        :tbody-tr-attr="rowAttr"
      >
        <template v-slot:cell(country)="data">
          <span :title="data.item.country">
            {{ data.item.country ? $t(data.item.country, '-F') : '❓' }}
          </span>
        </template>
        <template v-slot:cell(titlelink)="data">
          <a :href="data.item.website">
            <b class="text-info">{{ data.item.title }}</b>
          </a>
        </template>
        <template v-slot:cell(endpoint)="data">
          <a :href="data.item.endpoint">
            <b class="text-info">{{ urlize(data.item.endpoint) }}</b>
            <svg-icon
              name="external-link-alt"
              size="xs"
              variant="fc-g7"
            />
          </a>
        </template>
        <template v-slot:cell(chatons)="data">
          <a
            v-if="data.item.chatons"
            href="https://chatons.org"
          >
            {{ $t('page-info.instance.type.chatons') }}
          </a>
          <span v-else>
            {{ $t('page-info.instance.type.other') }}
          </span>
        </template>
      </b-table>
      <p v-html="$t('page-info.add-yourself')"></p>
    </section>

    <section>
      <h3>{{ $t('page-info.title-other', { nbinstances: untrustedItems.length }) }}</h3>
      <p>{{ $t('page-info.intro-other') }}</p>
      <b-table
        striped
        hover
        :items="untrustedItems"
        :fields="fieldsForUntrustedInstance"
        :tbody-tr-class="rowClass"
        :tbody-tr-attr="rowAttr"
      >
        <template v-slot:cell(country)="data">
          <span :title="data.item.country">
            {{ data.item.country ? $t(data.item.country, '-F') : '❓' }}
          </span>
        </template>
        <template v-slot:cell(titlelink)="data">
          <a :href="data.item.website">
            <b class="text-info">{{ data.item.title }}</b>
          </a>
        </template>
        <template v-slot:cell(endpoint)="data">
          <a :href="data.item.endpoint">
            <b class="text-info">{{ urlize(data.item.endpoint) }}</b>
            <svg-icon
              name="external-link-alt"
              size="xs"
              variant="fc-g7"
            />
          </a>
        </template>
      </b-table>
    </section>

    <section>
      <h3
        id="specific"
        v-html="$t('page-info.title-specific')"
      ></h3>
      <ul>
        <li><a href="https://webconf.numerique.gouv.fr/">Service de WebConférence de l'État Français</a> (réservé aux agents des services de l'État Français)</li>
        <li><a href="https://rendez-vous.renater.fr/">Service de WebConférence du <abbr title="Réseau National de télécommunications pour la Technologie l’Enseignement et la Recherche">RENATER</abbr></a> (réservé au personnel des établissements de la communauté Education Recherche rattachés à <abbr title="Réseau National de télécommunications pour la Technologie l’Enseignement et la Recherche">RENATER</abbr>).</li>
      </ul>
    </section>

    <section>
      <h3
        id="#bigbluebutton"
        v-html="$t('page-info.title-bbb')"
      ></h3>
      <p v-html="$t('page-info.description-bbb')"></p>
      <ul>
        <li><a href="https://bbb.faimaison.net/">Instance proposée</a> par <a href="https://www.faimaison.net/">FAImaison</a> (FAI associatif membre de la <a href="https://ffdn.org/"><abbr title="Fédération FDN">FFDN</abbr></a>).</li>
        <li><a href="https://demo.bigbluebutton.org/">Instance de démonstration de Big Blue Button</a>.</li>
        <li><a href="https://bbb.wsweet.cloud/">Instances proposées</a> par <a href="https://www.worteks.com/fr/">Worteks</a> (société d’expertise et d’édition en logiciels libres et open source)</li>
        <li><a href="https://meeting.hostelyon.fr/">Instance proposée</a> par <a href="https://www.hostelyon.fr/">hosTELyon</a> (data-center lyonnais)</li>
      </ul>
    </section>

    <section>
      <h3
        id="#resources"
        v-html="$t('page-info.resources.title')"
      ></h3>
      <ul>
        <li><a href="https://framasoft.frama.io/teletravail/">Le guide pour le télétravail de Framasoft</a></li>
        <li><a href="https://linuxfr.org/news/organiser-des-visioconferences-de-haute-qualite-avec-le-logiciel-libre-jitsi-meet">Organiser des visioconférences de haute qualité (avec le logiciel libre Jitsi Meet)</a>, par la société Algoo, sur LinuxFR</li>
        <li><a href="https://fr.wikibooks.org/wiki/Jitsi_meet">Le guide d'utilisation de Jitsi Meet</a> par Wikibooks</li>
      </ul>
    </section>
  </main>
</template>

<script>
import { shuffle } from 'lodash';
import instanceStatus from '../mixins/instanceStatus';

export default {
  mixins: [instanceStatus],

  data() {
    return {
      status: { failed: {}, instances: {} },
      items: shuffle(Object
        .values(this.$t('instances'))
        .filter(instance => !(instance.trust === false))),
      untrustedItems: shuffle(Object
        .values(this.$t('instances'))
        .filter(instance => instance.trust === false)),
      fieldsForUntrustedInstance: [
        {
          key: 'country',
          sortable: true,
          label: this.$t('page-info.columns.country'),
        },
        {
          key: 'titlelink',
          sortable: true,
          label: this.$t('page-info.columns.title'),
        },
        {
          key: 'endpoint',
          sortable: false,
          label: this.$t('page-info.columns.url'),
        },
      ],
      fields: [
        {
          key: 'country',
          sortable: true,
          label: this.$t('page-info.columns.country'),
        },
        {
          key: 'titlelink',
          sortable: true,
          label: this.$t('page-info.columns.title'),
        },
        {
          key: 'endpoint',
          sortable: false,
          label: this.$t('page-info.columns.url'),
        },
        {
          key: 'chatons',
          sortable: true,
          label: this.$t('page-info.columns.chatons'),
        },
      ],
    };
  },

  async created() {
    this.status = await (await fetch('https://framatalk.org/accueil/instances_check.json')).json();
  },

  methods: {
    urlize(endpoint) {
      return (new URL(endpoint)).hostname;
    },

    rowClass(item, type) {
      if (!item || type !== 'row') return '';
      if (this.instanceHasError(this.keyForEndpoint(item.endpoint))) return 'table-danger';
      return '';
    },

    rowAttr(item, type) {
      if (!item || type !== 'row') return '';
      const key = this.keyForEndpoint(item.endpoint);
      if (this.instanceHasError(key)) {
        return {
          title: this.truncateStatusString(this.status.failed[key]),
        };
      }
      return '';
    },

    truncateStatusString(statusString) {
      if (statusString.match(/HTTP code/)) {
        return statusString;
      }
      return statusString.slice(0, -60);
    },
  },
};
</script>

<style lang="scss">
#info .table {
  background: #fff;
}
</style>

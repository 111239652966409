var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"main-row",attrs:{"id":"intro"}},[_c('b-container',{staticClass:"px-md-0"},[_c('b-row',[_c('b-col',{attrs:{"md":"7","lg":"6"}},[_c('h1',{domProps:{"innerHTML":_vm._s(_vm.$t('hos.intro.title'))}}),_vm._v(" "),_c('h2',{domProps:{"innerHTML":_vm._s(_vm.$t('hos.intro.subtitle'))}}),_vm._v(" "),_c('ul',{staticClass:"buttons d-sm-none"},[_c('li',[_c('a',{staticClass:"btn btn-primary",attrs:{"href":"https://authent.framatalk.org/"},domProps:{"innerHTML":_vm._s(_vm.$t('hos.intro.btn-create-a-talk'))}})]),_vm._v(" "),_c('li',[_c('a',{staticClass:"btn btn-outline-warning",attrs:{"href":_vm.$t('hos.data.docs'),"onclick":"return false;"},on:{"click":function($event){_vm.docs = true}}},[_c('i',{staticClass:"fas fc-g6 fa-circle-play"}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('hos.intro.btn-tour'))}})])])]),_vm._v(" "),_c('ul',{staticClass:"buttons d-none d-sm-flex"},[_c('li',[_c('a',{staticClass:"btn btn-primary btn-lg",attrs:{"href":"https://authent.framatalk.org/"},domProps:{"innerHTML":_vm._s(_vm.$t('hos.intro.btn-create-a-talk'))}})]),_vm._v(" "),_c('li',[_c('a',{staticClass:"btn btn-lg btn-outline-warning",attrs:{"href":_vm.$t('hos.data.docs'),"onclick":"return false;"},on:{"click":function($event){_vm.docs = true}}},[_c('i',{staticClass:"fas fa-circle-play"}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('hos.intro.btn-tour'))}})])])]),_vm._v(" "),_c('b-alert',{attrs:{"show":"false","variant":_vm.ddOpen ? 'outline invisible' : 'outline'}},[(!_vm.instance.endpoint.includes('framatalk') )?_c('p',{domProps:{"innerHTML":_vm._s(`${
                _vm.$t('hos.intro.external', {
                  title: _vm.instance.title,
                  website: _vm.instance.website
                })}${
                _vm.instance.chatons ? _vm.$t('hos.intro.chatons') : '.'}`)}}):_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('hos.intro.frama-other'))}}),_vm._v(" "),_c('p',{staticClass:"m-0"},[_c('router-link',{attrs:{"to":`/${_vm.$t('lang')}/info`}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('hos.intro.full-list', { nbinstances: _vm.nbInstances }))}})])],1)])],1)],1),_vm._v(" "),_c('b-row',{staticClass:"d-none d-sm-flex",attrs:{"id":"learn-more","aria-hidden":"true","align-h":"center"}},[_c('input',{staticClass:"sr-only",attrs:{"id":"btn-learn-more","type":"checkbox"},on:{"change":function($event){_vm.$parent.$children[1].$refs['what']
            .scrollIntoView({ behavior: 'smooth' })}}}),_vm._v(" "),_c('label',{staticClass:"btn btn-sm btn-link p-0",attrs:{"for":"btn-learn-more"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('hos.intro.more', _vm.$t('hos.data')))}}),_vm._v(" "),_c('i',{staticClass:"fas fa-lg fa-chevron-down d-block fc-g8"})])])],1)],1),_vm._v(" "),_c('modal-docs',{attrs:{"link":_vm.$t('hos.data.docs'),"open":_vm.docs}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <section
      id="intro"
      class="main-row"
    >
      <b-container class="px-md-0">
        <b-row>
          <b-col
            md="7"
            lg="6"
          >
            <h1 v-html="$t('hos.intro.title')"></h1>
            <h2 v-html="$t('hos.intro.subtitle')"></h2>
            <!-- Mobile -->
            <ul class="buttons d-sm-none">
              <li>
                <a
                  class="btn btn-primary"
                  href="https://authent.framatalk.org/"
                  v-html="$t('hos.intro.btn-create-a-talk')"
                ></a>
              </li>
              <li>
                <a
                  class="btn btn-outline-warning"
                  :href="$t('hos.data.docs')"
                  onclick="return false;"
                  @click="docs = true"
                >
                  <i class="fas fc-g6 fa-circle-play"></i>
                  <span v-html="$t('hos.intro.btn-tour')"></span>
                </a>
              </li>
            </ul>
            <!-- Tablet/Desktop -->
            <ul class="buttons d-none d-sm-flex">
              <li>
                <a
                  class="btn btn-primary btn-lg"
                  href="https://authent.framatalk.org/"
                  v-html="$t('hos.intro.btn-create-a-talk')"
                ></a>
              </li>
              <li>
                <a
                  class="btn btn-lg btn-outline-warning"
                  :href="$t('hos.data.docs')"
                  onclick="return false;"
                  @click="docs = true"
                >
                  <i class="fas fa-circle-play"></i>
                  <span v-html="$t('hos.intro.btn-tour')"></span>
                </a>
              </li>
            </ul>

            <b-alert
              show="false"
              :variant="ddOpen ? 'outline invisible' : 'outline'"
            >
              <p
                v-if="!instance.endpoint.includes('framatalk') "
                v-html="`${
                  $t('hos.intro.external', {
                    title: instance.title,
                    website: instance.website
                  })}${
                  instance.chatons ? $t('hos.intro.chatons') : '.'}`"
              ></p>
              <p
                v-else
                v-html="$t('hos.intro.frama-other')"
              ></p>
              <p class="m-0">
                <router-link
                  :to="`/${$t('lang')}/info`"
                >
                  <span v-html="$t('hos.intro.full-list', { nbinstances: nbInstances })"></span>
                </router-link>
              </p>
            </b-alert>
          </b-col>
        </b-row>
        <b-row
          id="learn-more"
          aria-hidden="true"
          align-h="center"
          class="d-none d-sm-flex"
        >
          <input
            id="btn-learn-more"
            type="checkbox"
            class="sr-only"
            @change="$parent.$children[1].$refs['what']
              .scrollIntoView({ behavior: 'smooth' })"
          />
          <label
            for="btn-learn-more"
            class="btn btn-sm btn-link p-0"
          >
            <span v-html="$t('hos.intro.more', $t('hos.data'))"></span>
            <i class="fas fa-lg fa-chevron-down d-block fc-g8"></i>
          </label>
        </b-row>
      </b-container>
    </section>

    <modal-docs
      :link="$t('hos.data.docs')"
      :open="docs"
    />
  </div>
</template>

<script>
import instanceStatus from '../../mixins/instanceStatus';
import ModalDocs from '../../../commons/components/ModalDocs.vue';

const DEFAULT_WEIGHT = 2;

export default {
  components: {
    ModalDocs,
  },

  mixins: [instanceStatus],

  data() {
    /* Random alphanumeric name with 10 chars */
    const name = [...Array(10)].map(() => Math.random().toString(36)[3]).join('')
      .replace(/(.|$)/g, c => c[!Math.round(Math.random()) ? 'toString' : 'toLowerCase']());

    return {
      copy: false,
      docs: false,
      ddOpen: false,
      instance: this.$t('instances.framatalk'),
      instances: this.$t('instances'),
      suffix: `${Math.trunc(new Date().getTime() / 3600000).toString(36)}`,
      name,
      status: {},
    };
  },

  computed: {
    nbInstances() {
      return Object.keys(this.instances).length;
    },

    sname() {
      return this.$t(this.name, '-kL@').replace(/[^a-zA-Z0-9]/g, '');
    },

    url() {
      return `${this.instance.endpoint}${this.sname}${this.suffix}`;
    },
  },

  /* async mounted() {
    this.status = await (await fetch('https://framatalk.org/accueil/instances_check.json')).json();
    this.anotherInstance();
    while (this.keyForEndpoint(this.instance.endpoint) in this.status.failed) {
      this.anotherInstance();
    }
  }, */

  methods: {
    create() {
      window.location = this.url;
    },

    randomInstance() {
      const remoteInstancesSelected = Object.entries(this.instances)
        .filter(([, value]) => (!(value.trust === false)))
        .map(([key, instance]) => [key, (instance.weight || DEFAULT_WEIGHT)]);
      return this.instances[this.weightedRand(remoteInstancesSelected)];
    },

    weightedRand(data) {
      // First, we loop the main dataset to count up the total weight.
      // We're starting the counter at one because the upper boundary of Math.random() is exclusive.
      let total = 1;
      for (let i = 0; i < data.length; i += 1) {
        total += data[i][1];
      }
      // Total in hand, we can now pick a random value akin to our
      // random index from before.
      const threshold = Math.floor(Math.random() * total);

      // Now we just need to loop through the main data one more time
      // until we discover which value would live within this
      // particular threshold. We need to keep a running count of
      // weights as we go, so let's just reuse the "total" variable
      // since it was already declared.
      total = 0;
      for (let i = 0; i < data.length; i += 1) {
        // Add the weight to our running total.
        total += data[i][1];

        // If this value falls within the threshold, we're done!
        if (total >= threshold) {
          return data[i][0];
        }
      }
      // Should never happen
      return data[0][0];
    },

    anotherInstance() {
      let newInstance = this.instance;
      while (newInstance === this.instance) {
        newInstance = this.randomInstance();
      }
      this.instance = newInstance;
    },

    copyLink() {
      const input = this.$refs['share-link'];
      input.select();
      document.execCommand('copy');
      this.copy = true;
      setTimeout(() => { this.copy = false; }, 2000);
    },
  },
};
</script>

<style lang="scss">
#intro {
  filter: drop-shadow(2px 5px 4px rgba(0, 0, 0, 0.2));
  padding: 1rem 0 3rem;
  position: relative;
  z-index: 1;

  @media (max-width: 480px) {
    max-height: 600px;
  }

  &::before,
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &::before {
    background: var(--f-f7);
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 83%, 100% 0);
    clip-path: polygon(0 0, 0 100%, 100% 83%, 100% 0);

    @media (max-width: 768px) {
      -webkit-clip-path: polygon(0 0, 0 100%, 100% 93%, 100% 0);
      clip-path: polygon(0 0, 0 100%, 100% 93%, 100% 0);
    }
  }

  &::after {
    background: var(--f-g5);
    background-image: url('../../../public/img/intro.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 88%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 88%);

    @media (max-width: 768px) {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 98%, 0 95%);
      clip-path: polygon(0 0, 100% 0, 100% 98%, 0 95%);
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }

  h1 {
    color: var(--f-g8);
    font-size: 5rem;
    font-weight: 300;
    margin: 4rem 0 3rem;

    @media (max-width: 992px) {
      font-size: 4rem;
      margin: 3rem 0 2rem;
    }

    @media (max-width: 768px) {
      font-size: 3rem;
      margin: 2rem 0 1.5rem;
    }
  }

  h2 {
    color: var(--f-g8);
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.25;
    margin: 0;

    @media (max-width: 992px) {
      max-width: 75%;
      font-size: 1.75rem;
    }

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  #learn-more {
    label {
      color: var(--f-g8);
      background: none;
      text-decoration: none;
    }

    span {
      background: linear-gradient(180deg,#0000,#fff4,#0000);
      border-radius: 1rem;
      padding: .25rem ;
      font-size: .875rem;
    }

    input:checked ~ label {
      visibility: hidden;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    margin: 3rem 0 3.5rem;
    padding: 0;
    list-style: none;
    max-width: 90%;

    @media (max-width: 1200px) {
      flex-direction: column;
      max-width: 66%;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      max-width: 50%;
    }

    > li {
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 0;
      text-align: left;
      justify-content: center;
      align-items: center;
      margin: .25rem .75rem;

      @media (max-width: 1200px) {
        margin: .5rem 0 0;
      }

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      > * {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    }

    .btn {
      font-weight: bold;

      i.fas {
        margin-right: .25rem;
      }

      &.dropdown-toggle {
        max-width: 2.5rem;
      }
    }

    .btn-outline-warning {
      background: #fff;
      border-color: #fff;
      font-weight: bold;

      &:hover, &:focus, &:active {
        background: var(--f-o7);
        border-color: var(--f-o7);
        color: #fff;
      }
    }

    .dropdown-menu {
      background-color: var(--f-g1);
    }

    .btn-primary.dropdown-toggle-split {
      filter: brightness(.9)
    }
  }

  .alert-outline {
    background: #fbfcfddd;
    margin-bottom: 2.5rem;
    max-width: 90%;

    @media (max-width: 768px) {
      max-width: 50%;
    }

    @media (max-width: 576px) {
      font-size: .875rem;
      margin-top: 2.5rem;
      margin-bottom: 0;
      max-width: 100%;
    }
  }
}
</style>
